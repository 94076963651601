<template>
  <v-row>
    <v-col cols="12">
      <pibot-form-fieldset>
        <template #legend>
          Fatigue Assessment
        </template>

        <v-row>
          <v-col
            v-for="(field, i) in fields"
            :key="`form-field-${i}`"
            cols="12"
            md="6"
            lg="4"
          >
            <pibot-form-field
              :field="field"
              :loading="loading"
              :readonly="field.readonly || loading"
              :disabled="field.disabled"
              :placeholder="field.label"
              :error-messages="errors[field.model]"
              @change="onChange"
              outlined
            />
          </v-col>
        </v-row>
      </pibot-form-fieldset>
    </v-col>
  </v-row>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { $gte } from '@/utils/validations'
import assessment from '@/apps/eprs/utils/mixins/assessment.mixin'

const FORM = {
  'dent depth': undefined,
  'minimum operating pressure': undefined,
  'maximum operating pressure': undefined
}

export default {
  name: 'pibot-eprs-dent-form',
  components: {
    'pibot-form-fieldset': () => import('@/components/form-components/Fieldset'),
    'pibot-form-field': () => import('@/components/form-components/Field')
  },
  mixins: [assessment, validationMixin],
  validations: {
    form: {
      'dent depth': { required, $gte: (v) => $gte(v, 0.001) },
      'minimum operating pressure': { $gte: (v) => v === undefined || $gte(v, 0) },
      'maximum operating pressure': { $gte: (v) => v === undefined || $gte(v, 0) }
    }
  },
  data: () => ({
    form: FORM
  }),
  computed: {
    fields () {
      return [
        {
          type: 'number',
          model: 'dent depth',
          value: this.form['dent depth'],
          label: 'Dent Depth *',
          min: 0.01,
          step: 0.01,
          prefix: '',
          suffix: 'mm',
          icon: '',
          required: true
        },
        {
          type: 'number',
          model: 'minimum operating pressure',
          value: this.form['minimum operating pressure'],
          label: 'Minimum Operating Pressure',
          min: 0,
          step: 0.01,
          prefix: '',
          suffix: 'barg',
          icon: '',
          required: false
        },
        {
          type: 'number',
          model: 'maximum operating pressure',
          value: this.form['maximum operating pressure'],
          label: 'Maximum Operating Pressure',
          min: 0,
          step: 0.01,
          prefix: '',
          suffix: 'barg',
          icon: '',
          required: false
        }
      ]
    },
    errors () {
      const errors = {}

      for (const field of Object.values(this.fields)) {
        if (!this.$v.form[field.model].$invalid) {
          delete errors[field.model]
          continue
        }

        const messages = this.$v.form[field.model].$flattenParams().map(({ name }) => {
          if (name.includes('required')) return `${field.label} is required.`
          // else if (name.includes('gte')) return `${field.label} must be greater or equal to ${field.min}.`
          else if (name.includes('mustBeNumber')) return `${field.label} must be a number.`
          else if (name.includes('mustBeString')) return `${field.label} must be text.`
          else return undefined
        }).filter(v => !!v)

        errors[field.model] = messages
      }

      return errors
    }
  },
  async beforeMount () {
    this.reset()
    await this.init()
    this.validate()
  },
  methods: {
    onChange (v) {
      const update = { ...v }
      if (typeof update.value === 'number' && isNaN(update.value)) update.value = undefined
      this.$set(this.form, update.field.model, update.value)
      this.$v.form[v.field.model].$touch()
    },
    validate () {
      this.$v.$touch()
      return !this.$v.$invalid
    },
    reset () {
      this.form = Object.assign({}, FORM)
      this.$v.$reset()
    }
  }
}
</script>
